import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { MDXProvider } from '@mdx-js/react'

const components = {
          
}

//MDX allows you to control the rendering of page elements in your markdown. 
//MDXProvider is used to override specific markdown page elements in the React component
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {element}
    </ThemeProvider>
  </MDXProvider>
)

const theme = {
  transparentGreen: 'rgb(30, 150, 0, 0.5)',
  transparentRed: 'rgb(255, 0, 0, 0.5)',
  transparentWhite: 'rgb(255, 255, 255, 0.5)',
  green: '#0f9449',
  red: '#c11c2a'
}

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
  }

  body {
    overflow-x: hidden; 
    overflow-y: hidden;
  }

  html {
    box-sizing: border-box;
  }
`