// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-articles-template-js": () => import("./../src/templates/articlesTemplate.js" /* webpackChunkName: "component---src-templates-articles-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-category-template-js": () => import("./../src/templates/categoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */)
}

